import React from "react";
import { Link } from "gatsby";
import ArticleCard from "../article-card";

function CoctelesconSPP({ articles }) {
  return (
    <>
      <div className="container m-4 pb-2 text-center">
        <h5 className="uppercase">cocteles con</h5>
        <h1 className="uppercase text-sunpeakpeach">Boone’s Sun Peak Peach</h1>
      </div>
      <div className="grid grid-cols-1 gap-6 text-sunpeakpeach md:grid-cols-2 lg:grid-cols-3">
        {articles.slice(0, 3).map((article) => (
          <ArticleCard article={article} />
        ))}
      </div>
      <Link to="/blog">
        <button className="mx-auto my-5 block rounded bg-sunpeakpeach py-2 px-4 uppercase text-white md:w-1/2">
          Visitar el blog
        </button>
      </Link>
    </>
  );
}

export default CoctelesconSPP;
