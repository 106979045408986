import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../../components/Layout";
import { StaticImage } from "gatsby-plugin-image";
import Coctelescon from "../../components/Sabores/CoctelesconSPP";
import CarruselCompra from "../../components/CarruselCompra";
import BribeRecetas from "../../components/BribeRecetas";
import CarruselSabores from "../../components/CarruselSabores";
import Seo from "../../components/Seo";

function sunpeakpeach() {
  const { strapiTag } = useStaticQuery(graphql`
    query {
      strapiTag(name: { eq: "Sun Peak Peach" }) {
        articles {
          id
          slug
          title
          description
          cover {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.77)
              }
            }
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <Seo
        seo={{
          metaTitle: "Sun Peak Peach",
          metaDescription:
            "Boone’s Sun Peak Peach es una exquisita mezcla de vino de frutas con notas de melocotones y duraznos, con el nivel exacto entre dulzura y frescura.",
        }}
      />
      <header>
        <StaticImage
          src="../../assets/images/sabores/sunpeakpeach/SPP-BANNER.png"
          alt="Boones Sun Peak Peach"
          className="hero-img"
          placeholder="tracedSVG"
          layout="fullWidth"
        />
        <div className="container relative -mt-5 mb-20 md:-mt-28">
          <div className="z-10 flex flex-wrap justify-center gap-4">
            <div className="max-w-xl space-x-4 space-y-2 rounded-xl bg-white p-5 drop-shadow-xl md:h-56">
              <h1 className="text-center capitalize text-sunpeakpeach">
                Tu lado más dulce
              </h1>
              <p className="max-w-prose font-light">
                Boone’s Sun Peak Peach es una exquisita bebida preparada con
                notas de melocotones y duraznos, con el nivel exacto entre
                dulzura y frescura.
              </p>
              <p className="max-w-prose font-light">
                Tú y tus amigas pondrán el mood con Sun Peak Peach, ya sea para
                una noche de pelis románticas o un día de campo.
              </p>
            </div>
          </div>
        </div>
      </header>
      <main className="container">
        <div>
          <StaticImage
            src="../../assets/images/sabores/sunpeakpeach/info-peach-desktop.png"
            alt="Boones Sun Peak Peach"
            className="hero-img"
            placeholder="tracedSVG"
            layout="constrained"
          />
        </div>
        <div className="mt-4">
          <Coctelescon articles={strapiTag.articles} />
        </div>
        <div className="mt-16">
          <CarruselCompra />
        </div>
        <div className="mt-16">
          <BribeRecetas />
        </div>
        <div className="mt-16">
          <CarruselSabores />
        </div>
      </main>
    </Layout>
  );
}
export default sunpeakpeach;
